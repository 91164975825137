import useAppStore from "store"
import ENABLE_VALIDATIONS from "config/validations"
import useNavigateSearch from "./use_navigate_search"

const useAppRequest = (stepName, paramsMapper) => {
  // paramsMapper(need to save data and sync server validation errors)
  // key = front param name, value = server param name

  const errorMessages = useAppStore((state) => state.error_messages)
  const fields = useAppStore((state) => state.fields)
  const updateKey = useAppStore((state) => state.updateKey)

  const makeStepSuccess = useAppStore((state) => state.makeStepSuccess)
  const makeStepFailed = useAppStore((state) => state.makeStepFailed)
  const updateCurrentStep = useAppStore((state) => state.updateCurrentStep)
  const getNextStep = useAppStore((state) => state.getNextStep)

  const createApp = useAppStore((state) => state.createApp)
  const updateApp = useAppStore((state) => state.updateApp)
  const updateAppReferences = useAppStore((state) => state.updateAppReferences)
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)
  const navigateSearch = useNavigateSearch()

  const validate = () => {
    const valid = Object.keys(paramsMapper)
      .map((field) => !errorMessages[field])
      .every((v) => v === true)

    if (!valid && ENABLE_VALIDATIONS) {
      makeStepFailed(stepName)
    }

    return valid
  }

  const buildParams = () => {
    const nestedParams = {}

    Object.entries(paramsMapper).map((arr) => {
      const [key, value] = arr

      const [parent, child] = value.split(".")

      if (child !== undefined) {
        nestedParams[parent] ||= {}
        nestedParams[parent][child] = fields[key]
      } else {
        nestedParams[parent] = fields[key]
      }
    })

    return {
      step_name: stepName,
      [stepName]: nestedParams,
    }
  }

  const setErrorMessages = (messages) => {
    Object.entries(paramsMapper).map((arr) => {
      const [key, value] = arr

      updateErrorMessage(key, (messages[value] && messages[value][0]) || "")
    })
  }

  const handleSubmit = (event, callback = () => {}) => {
    event.preventDefault()

    if (!validate()) return

    const params = buildParams()

    return updateApp(params)
      .then((response) => response.data)
      .then((data) => {
        makeStepSuccess(stepName)

        const { status } = data
        updateKey("status", status)

        if (status === "accepted") {
          navigateSearch("/autoloan/full/application/success")
        } else if (status === "rejected") {
          navigateSearch("/autoloan/full/application/reject")
        } else {
          updateCurrentStep(getNextStep())
          callback()
        }
      })
      .catch((error) => {
        const messages = error?.response?.data?.error_messages
        console.log(error)

        if (messages) {
          setErrorMessages(messages)

          makeStepFailed(stepName)
        }
      })
  }

  const handleSubmitReferences = (event) => {
    event.preventDefault()

    if (!validate()) return

    const params = buildParams()

    return updateAppReferences(params)
      .then(() => {
        makeStepSuccess(stepName)
        updateCurrentStep(getNextStep())
      })
      .catch((error) => {
        const messages = error?.response?.data?.error_messages

        if (messages) {
          setErrorMessages(messages)

          makeStepFailed(stepName)
        }
      })
  }

  const handleCreateShortApp = (event, callback) => {
    event.preventDefault()

    if (!validate()) return

    const params = buildParams()

    return createApp(params)
      .then((response) => response?.data)
      .then((data) => {
        updateKey("uuid", data?.uuid)
        updateKey("status", data?.status)
        makeStepSuccess(stepName)

        callback(data?.status)
      })
      .catch((error) => {
        const messages = error?.response?.data?.error_messages

        if (messages) {
          setErrorMessages(messages)

          makeStepFailed(stepName)
        }
      })
  }

  return {
    handleSubmit,
    handleSubmitReferences,
    handleCreateShortApp,
  }
}

export default useAppRequest
