import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"

const Email = () => {
  const id = "email"

  const validate = (value) => {
    if (value === "") return "This field is required"

    if (value.length > 63) return "Is too long (maximum is 63 characters)"

    const emailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/

    if (!emailRegex.test(value)) return "Please enter a valid email address"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  return (
    <MyTextField
      id={id}
      _label="Email"
      placeholder="example@email.com"
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    />
  )
}

export default Email
