import { Box, Divider, List, ListItem, ListItemButton, ListItemText } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { pages } from "./Links"
import SubmitButton from "components/shared/buttons/Submit"
import LogoInfo from "./LogoInfo"
import CallPhone from "components/shared/Navbar/CallPhone"
import WorkingHours from "components/shared/Navbar/WorkingHours"

const DrawerLinks = (props) => {
  const { toggleDrawer } = props

  return (
    <Box sx={{ width: "80vw" }}>
      <Box sx={{ mx: 4, mt: 2.5, mb: 3, display: "flex", justifyContent: "space-between" }}>
        <LogoInfo />
        <CloseIcon onClick={toggleDrawer} />
      </Box>

      <Box sx={{ mx: 2 }}>
        <CallPhone />
        <WorkingHours />
      </Box>

      <List>
        {pages.map((page, i) => (
          <ListItem key={i}>
            <ListItemButton href={page.link} onClick={toggleDrawer}>
              <ListItemText primary={page.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />

      <Box sx={{ mx: 3, my: 2.5 }}>
        <SubmitButton fullWidth text="Take money" href="#entry" onClick={toggleDrawer} />
      </Box>
    </Box>
  )
}

export default DrawerLinks
