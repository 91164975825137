import { Box, Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material"
import Image from "static/docs_olp.png"
import SubmitButton from "components/shared/buttons/Submit"

const list = [
  "One valid government-issued photo ID",
  "Original copy of OR & CR",
  "Latest Electric or Water Bill",
  "TIN (Tax Identification Number)",
  "Income Documents, any of the following:",
  "Latest one month payslip",
  "Latest ITR / BIR Form 2316",
]

const DesktopRequiredDocs = () => {
  return (
    <Box id="required_documents">
      <Divider />
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            lg={6}
            sx={{
              borderRight: "1px solid #D2D2D2",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 516,
                width: 608,
                my: 11,
              }}
              alt="Documents"
              src={Image}
            />
          </Grid>
          <Grid item lg={6}>
            <Box sx={{ p: 12.5, flexGrow: 1 }}>
              <Typography variant="h3">Required docs</Typography>
              <Box component="ul" sx={{ ml: -3, mt: 4, mb: 5 }}>
                {list.map((item, key) => (
                  <Typography
                    component="li"
                    variant="h6"
                    key={key}
                    sx={{ mb: 1, fontFamily: "inter" }}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
              <SubmitButton text="Take money" href="#entry" />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Divider />
    </Box>
  )
}

const MobileRequiredDocs = () => {
  return (
    <Box id="required_documents">
      <Divider />
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          sx={{
            height: { xs: 201, md: 402 },
            width: { xs: 237, md: 472 },
            my: 4,
          }}
          alt="Documents"
          src={Image}
        />
      </Container>
      <Divider />

      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 5,
          flexDirection: "column",
        }}
      >
        <Typography variant="h3">Required docs</Typography>
        <Box component="ul" sx={{ ml: -2, mt: 2.5, mb: 0 }}>
          {list.map((item, key) => (
            <Typography component="li" variant="h6" key={key} sx={{ mb: 1 }}>
              {item}
            </Typography>
          ))}
        </Box>
      </Container>

      <Divider />
    </Box>
  )
}

const RequiredDocs = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("lg"))

  return matches ? <DesktopRequiredDocs /> : <MobileRequiredDocs />
}
export default RequiredDocs
