import { useEffect, useState } from "react"
import BaseSelect from "components/base/Select"

import useSelect from "hooks/use_select"
import useAppStore from "store"

const Occupation = () => {
  const id = "occupation_id"
  const [occupations, setOccupations] = useState([{ id: 999, name: "None" }])
  const [loaded, setLoaded] = useState(false)

  const getSocialStatuses = useAppStore((state) => state.getSocialStatuses)

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleOpen, handleClose, open } = useSelect(
    id,
    validate,
  )

  useEffect(() => {
    fetchSocialStatuses()
  }, [])

  const fetchSocialStatuses = () => {
    getSocialStatuses()
      .then((response) => response.data)
      .then((data) => {
        setOccupations(data)
        setLoaded(true)
      })
  }

  return (
    <BaseSelect
      id={id}
      label="Occupation"
      placeholder="Select your current occupation "
      error={errorMessage?.length > 0}
      value={loaded ? inputValue : 999}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
      items={occupations}
      helperText={errorMessage}
    />
  )
}

export default Occupation
