import { useState } from "react"
import useAppStore from "store"

const useSelect = (fieldName, validate) => {
  const inputValue = useAppStore((state) => state.fields[fieldName])
  const setInputValue = useAppStore((state) => state.updateField)
  const errorMessage = useAppStore((state) => state.error_messages[fieldName])
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)
  const [open, setOpen] = useState(false)

  const handleChange = (e) => {
    const { value } = e.target

    setInputValue(fieldName, value)
  }

  const handleClose = (e) => {
    const { value } = e.target

    const errorMessage = validate(value)

    updateErrorMessage(fieldName, errorMessage)

    if (fieldName === "occupation_id") {
      updateErrorMessage("regular_document", "")
    }

    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return {
    handleChange,
    inputValue,
    errorMessage,
    handleOpen,
    handleClose,
    open,
  }
}

export default useSelect
