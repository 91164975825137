import { Box, Typography } from "@mui/material"

const LogoInfo = (props) => {
  return (
    <Box {...props}>
      <Typography variant="caption" component="p" sx={{ color: "#7B8290" }}>
        powered by
      </Typography>
      <Typography variant="caption" component="p" sx={{ color: "#7B8290", mt: 0.5, minWidth: 200 }}>
        Online Loans Pilipinas
      </Typography>
    </Box>
  )
}

export default LogoInfo
