import { Radio, SvgIcon } from "@mui/material"
import { ReactComponent as Base } from "static/icons/radio/base.svg"
import { ReactComponent as Selected } from "static/icons/radio/selected.svg"

const RadioBase = () => <SvgIcon component={Base} />
const RadioSelected = () => <SvgIcon component={Selected} />

const BaseRadio = (props) => (
  <Radio
    icon={<RadioBase />}
    checkedIcon={<RadioSelected />}
    sx={{
      "&.Mui-checked": {
        color: "primary.main",
      },
    }}
    {...props}
  />
)

export default BaseRadio
