import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  FormHelperText,
  Typography,
} from "@mui/material"
import BaseRadio from "components/base/Radio"
import useInput from "hooks/use_input"
import { useState } from "react"

const CarIsCuf = () => {
  const id = "car_is_cuf"
  const [options] = useState([
    ["Yes", true],
    ["No", false],
  ])

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const { handleChange, handleBlur, inputValue, errorMessage } = useInput(id, validate)

  return (
    <FormControl sx={{ minWidth: 185 }} error={errorMessage?.length > 0}>
      <FormLabel>
        <Typography variant="body2" color="text.middle" sx={{ mb: 1 }}>
          Is your car under financing?
        </Typography>
      </FormLabel>
      <RadioGroup id={id} row value={inputValue} onChange={handleChange} onBlur={handleBlur}>
        {options.map((option) => (
          <FormControlLabel
            key={option[0]}
            value={option[1]}
            label={option[0]}
            control={<BaseRadio />}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

export default CarIsCuf
