import { Box, Container, Typography } from "@mui/material"

const BenefitsItem = (props) => {
  const { children, short } = props

  return (
    <Box
      id="benefits"
      sx={{
        display: "flex",
        alignItems: "top",
        mr: { xs: 0, lg: 6 },
        mb: { xs: 1.5, lg: 0 },
        minHeight: 80,
      }}
    >
      <Box
        sx={{
          backgroundColor: "bg.lightGreen",
          width: 80,
          height: 80,
          borderRadius: 40,
          position: "absolute",
          zIndex: -1,
        }}
      />
      <Box sx={{ ml: { xs: 4.5, lg: 5 }, mt: { xs: 3.5, lg: 3 }, minWidth: short ? 100 : 100 }}>
        {children}
      </Box>
    </Box>
  )
}

const Benefits = () => {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          my: { xs: 4, lg: 10 },
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between"
        }}
      >
        <BenefitsItem>
          <Typography variant="h5" sx={{ fontFamily: "inter"}}>
            Loan from PHP 100K
            <Typography variant="h5" component="span" sx={{ color: "primary.main", mx: 1,fontFamily: "inter" }}>
              up to PHP 2M
            </Typography>
            for 12-24 months
          </Typography>
        </BenefitsItem>

        <BenefitsItem short>
          <Typography variant="h5" sx={{ fontFamily: "inter"}}>
            Your
            <Typography variant="h5" component="span" sx={{ mx: 1 }}>
              car
            </Typography>
            <Typography variant="h5" component="span" sx={{ color: "primary.main", mr: 1, fontFamily: "inter" }}>
              stays with you
            </Typography>
          </Typography>
        </BenefitsItem>

        <BenefitsItem short>
          <Typography variant="h5" sx={{ fontFamily: "inter"}}>
            <Typography variant="h5" component="span" sx={{ color: "primary.main", mr: 1, fontFamily: "inter" }}>
              Without
            </Typography>
            guarantors
          </Typography>
        </BenefitsItem>

        <BenefitsItem>
          <Typography variant="h5" sx={{ fontFamily: "inter"}}>
            Fast process — 1
            <Typography variant="h5" component="span" sx={{ color: "primary.main", mx: 1, fontFamily: "inter" }}>
              business day
            </Typography>
            from application to disbursement of funds
          </Typography>
        </BenefitsItem>

        <BenefitsItem short>
          <Typography variant="h5" sx={{ fontFamily: "inter"}}>
            <Typography variant="h5" component="span" sx={{ color: "primary.main", mr: 1,fontFamily: "inter" }}>
              High
            </Typography>
            approval rate
          </Typography>
        </BenefitsItem>
      </Box>
    </Container>
  )
}

export default Benefits
