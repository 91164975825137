import InputMask from "react-input-mask"
import BaseField from "components/base/TextField"
import useInput from "hooks/use_input"

const DateOfBirth = () => {
  const id = "date_of_birth"

  const validate = (value) => {
    if (typeof value === "undefined") return ""

    if (value.match(/[A-z]/g) !== null) return "Please specify the date in MM-DD-YYYY format"

    // eslint-disable-next-line
    const [month, day, year] = value.split("-")

    if (month > 12 || day > 31) return "Please specify the date in MM-DD-YYYY format"

    const inputDate = new Date(year, month - 1, day)
    const minYearOld = new Date().setFullYear(new Date().getFullYear() - 14)
    const maxYearOld = new Date().setFullYear(new Date().getFullYear() - 100)

    if (inputDate > minYearOld) return "Invalid Date"

    if (inputDate < maxYearOld) return "Invalid Date"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  // https://blog.logrocket.com/implementing-react-input-mask-web-apps/
  const mask = [/[0-1]/, /[0-9]/, "-", /[0-3]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]

  return (
    <InputMask
      required
      maskPlaceholder="MM-DD-YYYY"
      mask={mask}
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    >
      <BaseField id={id} _label="Date of birth" inputProps={{ inputMode: "numeric" }} />
    </InputMask>
  )
}

export default DateOfBirth
