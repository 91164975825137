import { Button, Typography } from "@mui/material"

const SecondaryButton = (props) => {
  const { text } = props

  return (
    <Button
      variant="contained"
      disableElevation
      {...props}
      sx={{
        py: 1.5,
        px: 2.5,
        textTransform: "none",
        whiteSpace: "nowrap",
        backgroundColor: "secondary.outlined",
        color: "secondary.main",
        ":hover": {
          backgroundColor: "secondary.main",
          color: "secondary.contrastText",
        },
        // "&:disabled": {
        //   backgroundColor: "#0055FB",
        //   opacity: 0.5,
        //   color: "#fff",
        // },
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 700 }}>
        {text}
      </Typography>
    </Button>
  )
}

export default SecondaryButton
