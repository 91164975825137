import { useEffect, useState } from "react"
import ReactFileReader from "react-file-reader"
import { Box, Typography, Modal, Stack, FormHelperText, useMediaQuery } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import documentFront from "static/document_front.png"
import SubmitButton from "components/shared/buttons/Submit"
import ImagePreview from "../ImagePreview"
import UploadButton from "../buttons/Upload"
import ImageLoading from "../ImageLoading"

import useAppStore from "store"
import useModal from "hooks/use_modal"
import resizeImage from "config/resizer"

const tips = [
  "Should occupy more than 90% of the photo",
  "Front side of your valid ID, clear and visible",
  "Should not be expired",
  "Do not crop the document parts",
  "No highlights",
]

const DocumentFront = () => {
  const id = "front_document"
  const [url, setUrl] = useState("")
  const [loading, setLoading] = useState(true)

  const { open, handleOpen, handleClose } = useModal()

  const errorMessages = useAppStore((state) => state.error_messages)
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)

  const makeStepFailed = useAppStore((state) => state.makeStepFailed)

  const getDocumentPhoto = useAppStore((state) => state.getDocumentPhoto)
  const createDocumentPhoto = useAppStore((state) => state.createDocumentPhoto)
  const removeDocumentPhoto = useAppStore((state) => state.removeDocumentPhoto)

  useEffect(() => {
    fetchPhoto()
  }, [])

  const fetchPhoto = () => {
    getDocumentPhoto(id)
      .then((response) => response.data)
      .then((data) => {
        setUrl(data.url)
        setLoading(false)
        updateErrorMessage(id, "")
      })
  }

  const handleFiles = (files) => {
    handleClose()

    setLoading(true)

    const file = files[0]

    resizeImage(file)
      .then((resizedFile) => createDocumentPhoto(id, resizedFile))
      .then(() => fetchPhoto())
  }

  const resetImg = () => {
    removeDocumentPhoto(id).then(() => {
      setUrl("")
      makeStepFailed("government_issued_id")
    })
  }

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  const Upload = () => (
    <>
      <Box onClick={handleOpen}>
        <UploadButton text="Attach front side of your ID" />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 470,
            backgroundColor: "background.paper",
            boxShadow: 24,
            outline: "none",
            p: { xs: 2, md: 3 },
            borderRadius: "10px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Attach a front of the document
            </Typography>
            <CloseIcon
              sx={{
                ml: 2,
                width: 24,
                height: 24,
                fontWeight: 700,
                cursor: "pointer",
                color: "text.secondary",
              }}
              onClick={handleClose}
            />
          </Box>

          <Box id="modal-modal-description" sx={{ my: 2.5 }}>
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              Follow the given tips
            </Typography>
          </Box>
          <Stack spacing={1}>
            {tips.map((tip, i) => (
              <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 32,
                    height: 32,
                    borderRadius: 16,
                    backgroundColor: "bg.lightGreen",
                  }}
                >
                  <Typography variant="body2" sx={{ color: "primary.main" }}>
                    {i + 1}
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ ml: 1.5 }}>
                  {tip}
                </Typography>
              </Box>
            ))}
          </Stack>

          <Box
            component="img"
            sx={{
              my: 2,
              height: { xs: "auto", md: 253 },
              width: { xs: "100%", md: 410 },
            }}
            alt="Front document"
            src={documentFront}
          />

          <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} handleFiles={handleFiles}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", pr: { md: 2 } }}>
              <SubmitButton text="Continue" fullWidth={!matches} />
            </Box>
          </ReactFileReader>
        </Box>
      </Modal>
    </>
  )

  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 1, color: "text.middle" }}>
        Document Photo
      </Typography>

      {loading ? (
        <ImageLoading />
      ) : url.length > 0 ? (
        <ImagePreview url={url} resetImg={resetImg} />
      ) : (
        <Upload />
      )}

      <FormHelperText error={errorMessages.front_document !== ""}>
        {errorMessages.front_document}
      </FormHelperText>
    </Box>
  )
}

export default DocumentFront
