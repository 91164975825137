import { Box, SvgIcon, Typography } from "@mui/material"

import { ReactComponent as Hours } from "static/icons/hours.svg"

const HoursIcon = (props) => <SvgIcon component={Hours} inheritViewBox {...props} />

const WorkingHours = () => {
  return (
    <Box sx={{ display: "flex", ml: 2, mr: 4, alignItems: "center", mt: { xs: 3, md: 0 } }}>
      <HoursIcon />
      <Box sx={{ ml: 2 }}>
        <Typography variant="h6" component="h6" sx={{ fontWeight: 700 }}>
          8:00-17:00 business hours
        </Typography>
      </Box>
    </Box>
  )
}

export default WorkingHours
