import { useState, useEffect } from "react"
import { Box, FormHelperText, Grid } from "@mui/material"

import Instructions from "../Instructions"
import ImagePreview from "../ImagePreview"
import ImageLoading from "../ImageLoading"

import useAppStore from "store"
import ReactFileReader from "react-file-reader"
import UploadButton from "components/shared/buttons/Upload"
import resizeImage from "../../../config/resizer"

const Gallery = () => {
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(true)

  const errorMessages = useAppStore((state) => state.error_messages)
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)
  const getCarPhotos = useAppStore((state) => state.getCarPhotos)
  const createCarPhoto = useAppStore((state) => state.createCarPhoto)
  const removeCarPhoto = useAppStore((state) => state.removeCarPhoto)

  useEffect(() => {
    fetchPhotos()
  }, [])

  const fetchPhotos = () => {
    getCarPhotos()
      .then((response) => response.data)
      .then((data) => {
        setImages(data.images)
        setLoading(false)
        updateErrorMessage("car_photos", "")
      })
  }

  const handleFiles = (files) => {
    setLoading(true)

    Promise.all(Array.from(files).map((file) => resizeImage(file)))
      .then((resizedImages) => createCarPhoto(resizedImages))
      .then(() => fetchPhotos())
  }

  const resetImg = (name) => {
    removeCarPhoto(name).then(() => {
      setImages(images.filter((image) => image.id !== name))
    })
  }

  return (
    <Box>
      <Instructions />
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {images.length > 0 &&
          images.map((image, i) => (
            <Grid key={i} item xs={6} md={4}>
              <ImagePreview url={image.url} id={image.id} resetImg={resetImg} />
            </Grid>
          ))}
        <Grid item xs={6} md={4}>
          {images.length < 10 && (
            <ReactFileReader
              fileTypes={[".png", ".jpg", ".jpeg"]}
              multipleFiles={true}
              handleFiles={handleFiles}
            >
              {loading ? <ImageLoading /> : <UploadButton text="Take photos of your car" />}
            </ReactFileReader>
          )}
        </Grid>
      </Grid>
      <FormHelperText error={errorMessages.car_photos !== ""}>
        {errorMessages.car_photos}
      </FormHelperText>
    </Box>
  )
}

export default Gallery
