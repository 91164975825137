import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"

const LoanPurpose = () => {
  const id = "loan_purpose"

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  return (
    <MyTextField
      id={id}
      _label="Loan purpose"
      placeholder=""
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    />
  )
}

export default LoanPurpose
