import { Box, Container, Grid } from "@mui/material"
import coinsImage from "static/coins.png"

const StepsLayout = (props) => {
  const { children } = props

  return (
    <Container maxWidth="xl">
      <Grid container sx={{ my: { xs: 3, md: 6 }, minHeight: 400 }}>
        <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }} />

        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              maxWidth: "645px",
            }}
          >
            {children}
          </Box>
        </Grid>

        <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mr: -20 }}>
            <Box
              component="img"
              sx={{
                height: 480,
                width: 436,
              }}
              alt="coins"
              src={coinsImage}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default StepsLayout
