import { TextField, Grid, InputLabel, Typography } from "@mui/material"

const BaseTextField = (props) => {
  const { id, _label } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputLabel shrink={false} htmlFor={id}>
          <Typography variant="body2" color="text.middle">
            {_label}
          </Typography>
        </InputLabel>

        <TextField
          id={id}
          fullWidth
          margin="normal"
          variant="outlined"
          name={id}
          InputLabelProps={{
            shrink: true,
          }}
          {...props}
          sx={{
            mt: "6px",
            mb: { xs: 2, md: 2.5 },
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
              backgroundColor: "#F8F8FA",
              height: 50,
              fontWeight: 400,
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

// "&:hover.MuiOutlinedInput-root": {
//   color: "text.primary",
// },
// "&.Mui-focused.MuiOutlinedInput-root": {
//   color: "text.primary",
// },

export default BaseTextField
