import Navbar from "components/shared/Navbar"
import Footer from "components/shared/Footer"

const ApplicationLayout = (props) => {
  const { children } = props

  return (
    <>
      <Navbar onlyLogo={true} />
      {children}
      <Footer />
    </>
  )
}

export default ApplicationLayout
