import { create } from "zustand"
import { persist } from "zustand/middleware"
// import $api from "config/api"

const initialState = {
  utm_params: {
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
    click_id: "",
  },
}

const useClientStore = create(
  persist(
    // eslint-disable-next-line no-unused-vars
    (set, get) => ({
      ...initialState,

      updateUtmParam: (key, value) =>
        set((state) => ({ utm_params: { ...state.utm_params, [key]: value || "" } })),
    }),
    {
      name: "client",
    },
  ),
)

export default useClientStore
