import { Box, Divider, Grid, Paper, Typography, SvgIcon, Container } from "@mui/material"

import { ReactComponent as Head } from "static/icons/head_solid.svg"
import { ReactComponent as BackPack } from "static/icons/backpack_solid.svg"
import { ReactComponent as Vehicles } from "static/icons/vehicles_solid.svg"

const HeadIcon = (props) => <SvgIcon component={Head} inheritViewBox {...props} />
const BackPackIcon = (props) => <SvgIcon component={BackPack} inheritViewBox {...props} />
const VehiclesIcon = (props) => <SvgIcon component={Vehicles} inheritViewBox {...props} />

const EligibilityItem = (props) => {
  const { icon, title, list } = props

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", ml: { xs: 1, md: 2 } }}>
        {icon}
        <Typography variant="h4" sx={{ ml: { xs: 1.5, md: 3 }, mt: 0.5 }}>
          {title}
        </Typography>
      </Box>
      <Divider sx={{ my: { xs: 1.5, md: 3 } }} />
      <Box component="ul" sx={{ ml: -2.5 }}>
        {list.map((item, key) => (
          <Typography component="li" variant="h6" key={key} sx={{ mb: 1, fontFamily: "inter" }}>
            {item}
          </Typography>
        ))}
      </Box>
    </Box>
  )
}

const Eligibility = () => {
  return (
    <Box sx={{ backgroundColor: "bg.main" }}>
      <Container maxWidth="xl">
        <Paper
          id="eligibility"
          elevation={0}
          sx={{
            backgroundColor: "transparent",
            pt: { xs: 8, lg: 8 },
            pb: { xs: 6, lg: 13 },
          }}
        >
          <Typography variant="h3">Eligibility criteria</Typography>
          <Grid container sx={{ mt: { xs: 4, md: 5 }, mr: 6 }} columnSpacing={10}>
            <Grid item xl={6} xs={12}>
              <EligibilityItem
                icon={<HeadIcon sx={{ width: 50, height: 50 }} />}
                title="Borrower"
                list={[
                  "At least 21 years old but not more than 65 years old upon loan maturity",
                  "Must be a Filipino citizen",
                  "Residence or office is within Metro Manila",
                ]}
              />

              <Box sx={{ mt: { xs: 3.5, md: 7.5 } }} />

              <EligibilityItem
                icon={<BackPackIcon sx={{ width: 50, height: 50 }} />}
                title="Employed"
                list={[
                  "Regular and permanent with current employer and employed for at least six (6) months",
                  "Minimum gross monthly income: PHP30,000",
                ]}
              />
            </Grid>

            <Box sx={{ mt: { xs: 3.5, md: 0 } }} />

            <Grid item xl={6} xs={12} sx={{ mt: { xs: 3.5, md: 0 } }}>
              <EligibilityItem
                icon={<VehiclesIcon sx={{ width: 50, height: 50 }} />}
                title="For Vehicle"
                list={[
                  "Must be registered under borrower’s name",
                  "Must be owned by borrower for at least 6 months",
                  "Should not be encumbered",
                  "Car type must either be passenger or pick-up car",
                  "Cannot be for commercial use (e.g. used for Grab / TNVS), and cannot be a public utility vehicle / yellow plate (e.g. taxi, jeepney, UV express, FX, etc.)",
                  "Can be brand new or used",
                  "Maximum age of 10 years at point of application",
                ]}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  )
}

export default Eligibility
