import { Box, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const ImagePreview = (props) => {
  const { url, id, resetImg } = props

  return (
    <Box
      sx={{
        border: "1px dashed #E0E0E7",
        borderRadius: "4px",
        p: 1,
        cursor: "pointer",
        height: 137,
        width: "auto",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          height: 121,
        }}
      >
        <IconButton
          onClick={() => resetImg(id)}
          disableRipple
          sx={{
            position: "absolute",
            right: 8,
            bottom: 8,
            backgroundColor: "secondary.outlined",
            color: "secondary.main",
            cursor: "pointer",
          }}
        >
          <CloseIcon sx={{ width: 12, height: 12, fontWeight: 700 }} />
        </IconButton>

        <Box
          component="img"
          // onLoad={(event) => {
          //   if (process.env.NODE_ENV !== "production") {
          //     console.log(
          //       `WIDTH: ${event.target.naturalWidth}. HEIGHT: ${event.target.naturalHeight}`,
          //     )
          //   }
          // }}
          alt="Bill"
          src={url}
          sx={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            borderRadius: "4px",
          }}
        />
      </Box>
    </Box>
  )
}

export default ImagePreview
