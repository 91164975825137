import InputMask from "react-input-mask"
import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"

const CompanyPhoneNumber = () => {
  const id = "viber_phone"

  const validate = () => {}

  const { handleChange, inputValue, errorMessage, handleBlur, focused, handleFocus } = useInput(
    id,
    validate,
  )

  const mask = [
    "0",
    "9",
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ]

  return (
    // https://blog.logrocket.com/implementing-react-input-mask-web-apps/
    <InputMask
      required
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      // onMouseLeave={handleBlur}
      onFocus={handleFocus}
      focused={focused}
      helperText={errorMessage}
    >
      <MyTextField id={id} _label="Viber" inputProps={{ inputMode: "numeric" }} />
    </InputMask>
  )
}
export default CompanyPhoneNumber
