import ApplicationLayout from "../../Layout"
import { Box, Container, Typography } from "@mui/material"
import Image from "static/reject_page_short.png"
import { useEffect } from "react"
import useAppStore from "store"

const Reject = () => {
  const resetState = useAppStore((store) => store.resetState)

  useEffect(() => {
    resetState()
    window.scrollTo(0, 0)

    if (typeof window.fbq !== "undefined" && window.fbq !== null) {
      window.fbq("track", "Lead")
      window.fbq("track", "ApplicationReject")
    }
  }, [])

  return (
    <ApplicationLayout>
      <Container maxWidth="lg">
        <Box
          sx={{
            height: { xs: 600, xl: 800 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: { xs: 135, md: 155 },
              width: { xs: 227, md: 262 },
            }}
            alt="Documents"
            src={Image}
          />
          <Typography
            variant="h3"
            sx={{ maxWidth: { xs: 350, md: 550 }, mt: 7, textAlign: "center" }}
          >
            Thank you for your interest!
          </Typography>

          <Typography
            variant="h6"
            sx={{ maxWidth: { xs: 350, md: 550 }, mt: 2, textAlign: "center" }}
          >
            For now we will only offer loans secured by a car. As soon as we introduce new loan
            products, we will contact you immediately!
          </Typography>
        </Box>
      </Container>
    </ApplicationLayout>
  )
}

export default Reject
