import { Box, Container, Stack, Typography, SvgIcon } from "@mui/material"
import useAppStore from "../../store"
import { ReactComponent as Success } from "static/icons/success.svg"

const SuccessIcon = () => <SvgIcon component={Success} />

const ProgressItem = (props) => {
  const { text, passed, active } = props
  let color
  let borderColor
  let bold

  const size = 26

  if (passed) {
    color = "#00B71D"
    borderColor = "#D3FFD7"
    bold = 700
  } else if (active) {
    color = "primary.contrastText"
    borderColor = "primary.main"
    bold = 700
  } else {
    color = "text.middle"
    borderColor = "#EFEFEF"
    bold = 400
  }

  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        height: `${size * 2}px`,
        px: { xs: 1, md: 3 },
        mr: 3,
        backgroundColor: borderColor,
        color: color,
        "&:first-of-type": {
          "a:before": {
            content: "none",
          },
        },
      }}
    >
      <Box
        component="a"
        sx={{
          display: "flex",
          alignItems: "center",
          "&:before, &:after": {
            content: '""',
            position: "absolute",
            top: 0,
            borderStyle: "solid",
            borderWidth: `${size}px 0 ${size}px ${size / 2}px`,
          },
          "&:before": {
            right: "100%",
            borderColor: borderColor,
            borderLeftColor: "transparent",
          },
          "&:after": {
            left: "100%",
            borderColor: "transparent",
            borderLeftColor: borderColor,
          },
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: bold, mr: passed ? 2 : 0 }}>
          {text}
        </Typography>
        {passed && <SuccessIcon />}
      </Box>
    </Box>
  )
}

const Progress = () => {
  const currentStep = useAppStore((state) => state.current_step)

  const step1Active = [
    "general_info",
    "references",
    "residence",
    "government_issued_id",
    "financial_info",
  ].includes(currentStep)

  const step2Active = ["car_general_info", "car_documents", "car_photos"].includes(currentStep)

  return (
    <Container maxWidth="xl" sx={{ mt: 3.5 }}>
      <Stack
        direction="row"
        component="ol"
        sx={{
          listStyle: "none",
          padding: 0,
          margin: 0,
        }}
      >
        <ProgressItem text="1. Application form" passed={!step1Active} active={step1Active} />
        <ProgressItem
          text="2. Car details step"
          passed={!step1Active && !step2Active}
          active={step2Active}
        />
      </Stack>
    </Container>
  )
}

export default Progress
