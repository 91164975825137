// import AttachFileIcon from "@mui/icons-material/AttachFile"
import { Box, Typography } from "@mui/material"
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"

const UploadButton = (props) => {
  const { text } = props

  return (
    <Box
      sx={{
        border: "1px dashed #E0E0E7",
        borderRadius: "4px",
        p: 1,
        cursor: "pointer",
        height: 137,
        width: "auto",
      }}
    >
      <Box
        sx={{
          px: { xs: 2, md: 5 },
          py: { xs: 1.5, md: 3 },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F5F7F8",
          borderRadius: "4px",
          height: 121,
        }}
      >
        <AddAPhotoIcon
          sx={{
            height: 32,
            width: 32,
            mb: 2,
            color: "primary.main",
          }}
        />

        <Typography
          variant="caption"
          sx={{ color: "text.secondary", textAlign: "center", maxWidth: 160 }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default UploadButton
