import { useEffect, useState } from "react"
import ReactFileReader from "react-file-reader"
import { Box, Typography, FormHelperText } from "@mui/material"

import UploadButton from "components/shared/buttons/Upload"
import ImagePreview from "../ImagePreview"
import ImageLoading from "../ImageLoading"

import useAppStore from "store"
import resizeImage from "config/resizer"

const CarDocumentCr = () => {
  const id = "car_document_cr"
  const [url, setUrl] = useState("")
  const [loading, setLoading] = useState(true)

  const errorMessages = useAppStore((state) => state.error_messages)
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)

  const makeStepFailed = useAppStore((state) => state.makeStepFailed)

  const getCarDocumentPhoto = useAppStore((state) => state.getCarDocumentPhoto)
  const createCarDocumentPhoto = useAppStore((state) => state.createCarDocumentPhoto)
  const removeDocumentPhoto = useAppStore((state) => state.removeDocumentPhoto)

  useEffect(() => {
    fetchPhoto()
  }, [])

  const fetchPhoto = () => {
    getCarDocumentPhoto(id)
      .then((response) => response.data)
      .then((data) => {
        setUrl(data.url)
        setLoading(false)
        updateErrorMessage(id, "")
      })
  }

  const handleFiles = (files) => {
    setLoading(true)

    const file = files[0]

    resizeImage(file)
      .then((resizedFile) => createCarDocumentPhoto(id, resizedFile))
      .then(() => fetchPhoto())
  }

  const resetImg = () => {
    removeDocumentPhoto(id).then(() => {
      setUrl("")
      makeStepFailed("car_documents")
    })
  }

  const Upload = () => (
    <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} handleFiles={handleFiles}>
      <UploadButton text="Please attach the Certificate of Registration" />
    </ReactFileReader>
  )

  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 1, color: "text.middle" }}>
        Attach the CR
      </Typography>

      {loading ? (
        <ImageLoading />
      ) : url.length > 0 ? (
        <ImagePreview url={url} resetImg={resetImg} />
      ) : (
        <Upload />
      )}

      <FormHelperText error={errorMessages.car_document_cr !== ""}>
        {errorMessages.car_document_cr}
      </FormHelperText>
    </Box>
  )
}

export default CarDocumentCr
