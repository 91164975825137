import { ReactComponent as LogoIconWhite } from "static/logo_olp_white.svg"
import { ReactComponent as LogoIconMobileWhite } from "static/logo_olp_white_mobile.svg"

import { ReactComponent as LogoIcon } from "static/logo_olp.svg"
import { ReactComponent as LogoIconMobile } from "static/logo_olp_mobile.svg"

import { SvgIcon } from "@mui/material"

const Logo = (props) => {
  const { mobile, white } = props

  let component

  if (mobile) {
    if (white) {
      component = LogoIconMobileWhite
    } else {
      component = LogoIconMobile
    }
  } else {
    if (white) {
      component = LogoIconWhite
    } else {
      component = LogoIcon
    }
  }

  return <SvgIcon component={component} inheritViewBox {...props} />
}

export default Logo
