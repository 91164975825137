import { Box, Grid } from "@mui/material"

import useAppRequest from "hooks/use_app_request"

import StepBlock from "components/shared/StepBlock"
import CarDocumentOr from "components/shared/fields/CarDocumentOr"
import CarDocumentCr from "components/shared/fields/CarDocumentCr"
import SecondaryButton from "components/shared/buttons/Secondary"

const Documents = () => {
  const stepName = "car_documents"

  const { handleSubmit } = useAppRequest(stepName, {
    car_document_cr: "car_document_cr",
    car_document_or: "car_document_or",
  })

  return (
    <StepBlock stepName={stepName} stepTitle="Documents">
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ mb: 2.5 }}>
          <Grid item xs={6} md={4}>
            <CarDocumentOr />
          </Grid>
          <Grid item xs={6} md={4}>
            <CarDocumentCr />
          </Grid>
        </Grid>
        <SecondaryButton text="Next" type="submit" />
      </Box>
    </StepBlock>
  )
}

export default Documents
