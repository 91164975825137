import { useNavigate, createSearchParams } from "react-router-dom"
import useClientStore from "store/client"

const useNavigateSearch = () => {
  const utmParams = useClientStore((state) => state.utm_params)
  const navigate = useNavigate()

  const utmParamsWithoutEmpty = Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(utmParams).filter(([_key, value]) => value !== ""),
  )

  return (pathname) =>
    navigate({ pathname, search: `?${createSearchParams(utmParamsWithoutEmpty)}` })
}

export default useNavigateSearch
