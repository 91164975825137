import { Box, Divider, Grid, Paper, Typography, SvgIcon, Container } from "@mui/material"
import Image from "static/first_place_olp.png"

import { ReactComponent as Head } from "static/icons/head.svg"
import { ReactComponent as BackPack } from "static/icons/backpack.svg"
import { ReactComponent as Vehicles } from "static/icons/vehicles.svg"

const HeadIcon = (props) => <SvgIcon component={Head} inheritViewBox {...props} />
const BackPackIcon = (props) => <SvgIcon component={BackPack} inheritViewBox {...props} />
const VehiclesIcon = (props) => <SvgIcon component={Vehicles} inheritViewBox {...props} />

const EligibilityItem = (props) => {
  const { icon, title, list } = props

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", ml: { xs: 1, md: 2 } }}>
        {icon}
        <Typography variant="h4" sx={{ ml: { xs: 1.5, md: 3 }, mt: 0.5 }}>
          {title}
        </Typography>
      </Box>
      <Divider sx={{ my: { xs: 1.5, md: 3 } }} />
      <Box component="ul" sx={{ ml: -2.5 }}>
        {list.map((item, key) => (
          <Typography component="li" variant="h6" key={key} sx={{ mb: 1, fontFamily: "inter" }}>
            {item}
          </Typography>
        ))}
      </Box>
    </Box>
  )
}

const Eligibility = () => {
  return (
    <Container maxWidth="xl">
      <Paper
        id="eligibility"
        elevation={0}
        sx={{
          borderRadius: "20px",
          backgroundColor: "bg.main",
          px: { xs: 2.5, lg: 7.5 },
          pt: { xs: 4, lg: 8 },
          pb: { xs: 6, lg: 13 },
        }}
      >
        <Typography variant="h3">Eligibility criteria</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between",
          }}
        >
          <Grid container sx={{ mt: { xs: 4, md: 5 }, mr: 6 }} columnSpacing={10}>
            <Grid item xl={6} xs={12}>
              <EligibilityItem
                icon={<HeadIcon sx={{ width: 32, height: 32 }} />}
                title="Borrower"
                list={[
                  "At least 21 years old but not more than 65 years old upon loan maturity",
                  "Must be a Filipino citizen",
                  "Residence or office is within Metro Manila",
                ]}
              />

              <Box sx={{ mt: { xs: 3.5, md: 7.5 } }} />

              <EligibilityItem
                icon={<BackPackIcon sx={{ width: 32, height: 32 }} />}
                title="Employed"
                list={[
                  "Regular and permanent with current employer and employed for at least six (6) months",
                  "Minimum gross monthly income: PHP30,000",
                ]}
              />
            </Grid>

            <Box sx={{ mt: { xs: 3.5, md: 0 } }} />

            <Grid item xl={6} xs={12} sx={{ mt: { xs: 3.5, md: 0 } }}>
              <EligibilityItem
                icon={<VehiclesIcon sx={{ width: 32, height: 32 }} />}
                title="For Vehicle"
                list={[
                  "Must be registered under borrower’s name",
                  "Must be owned by borrower for at least 6 months",
                  "Should not be encumbered",
                  "Car type must either be passenger or pick-up car",
                  "Cannot be for commercial use (e.g. used for Grab / TNVS), and cannot be a public utility vehicle / yellow plate (e.g. taxi, jeepney, UV express, FX, etc.)",
                  "Can be brand new or used",
                  "Maximum age of 10 years at point of application",
                ]}
              />
            </Grid>
          </Grid>

          <Box
            component="img"
            sx={{
              height: { md: 489 },
              width: { md: 510 },
              my: { xs: 3.5, md: 4, xl: 0 },
              mx: { md: "auto" },
            }}
            alt="First place"
            src={Image}
          />
        </Box>
      </Paper>
    </Container>
  )
}

export default Eligibility
