import { Container, Box, Paper, Typography, useMediaQuery } from "@mui/material"
import DesktopImage from "static/bg_main_olp.png"
import MobileImage from "static/bg_main_mobile_down_olp.png"

import Calculator from "components/shared/Calculator"

const DesktopEntry = () => {
  return (
    <Paper
      id="entry"
      elevation={0}
      sx={{
        borderRadius: "20px",
        px: 7.5,
        py: 8,
        display: "flex",
        justifyContent: "space-between",
        backgroundImage: `url(${DesktopImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box sx={{ width: "590px" }}>
        <Typography variant="h2">Auto Loan Sangla OR/CR</Typography>
        <Box sx={{ width: "500px" }}>
          <Typography variant="h4" sx={{ mt: 1.5, lineHeight: "33px", fontFamily:"inter" }}>
            Keep using your car while your OR/CR gets you our lowest interest loan
          </Typography>
        </Box>
      </Box>

      <Calculator />
    </Paper>
  )
}

const MobileEntry = () => {
  return (
    <Paper
      id="entry"
      elevation={0}
      sx={{
        borderRadius: "20px",
        px: 2,
        py: 3.5,
        // display: "flex",
        // justifyContent: "space-between",
        // backgroundImage: `url(${MobileImage})`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundColor: "bg.main",
      }}
    >
      <Box>
        <Typography variant="h2">OR/CR Sangla Loan</Typography>
        <Typography variant="h6" sx={{ mt: 1.5, mb: 2.5, lineHeight: "22px" }}>
          Keep using your car while your OR/CR gets you our lowest interest loan
        </Typography>
      </Box>
      <Calculator />
      <Box
        sx={{
          mt: 2,
          width: 330,
          height: 322,
          backgroundImage: `url(${MobileImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></Box>
    </Paper>
  )
}

const Entry = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  const child = matches ? <DesktopEntry /> : <MobileEntry />

  return <Container maxWidth="xl">{child}</Container>
}

export default Entry
