import { Box, Stack, useMediaQuery } from "@mui/material"

import useAppRequest from "hooks/use_app_request"

import StepBlock from "components/shared/StepBlock"
import FullName from "components/shared/fields/FullName"
import DateOfBirth from "components/shared/fields/DateOfBirth"
import Gender from "components/shared/fields/Gender"
import Email from "components/shared/fields/Email"
import ViberPhone from "components/shared/fields/ViberPhone"
import FacebookLink from "components/shared/fields/FacebookLink"
import LoanPurpose from "components/shared/fields/LoanPurpose"
import SecondaryButton from "components/shared/buttons/Secondary"

const GeneralInfo = () => {
  const stepName = "general_info"

  const { handleSubmit } = useAppRequest(stepName, {
    full_name: "full_name",
    date_of_birth: "date_of_birth",
    gender: "gender",
    email: "email",
    viber_phone: "viber_phone",
    facebook_link: "facebook_link",
    loan_purpose: "loan_purpose",
  })

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <StepBlock stepName={stepName} stepTitle="General info">
      <Box component="form" onSubmit={handleSubmit}>
        <Box sx={{ maxWidth: "388px" }}>
          <FullName />

          <Stack
            direction={matches ? "row" : "column"}
            spacing={matches ? 3 : 0}
            sx={{ mb: { xs: 2, md: 0 } }}
          >
            <DateOfBirth />
            <Gender />
          </Stack>
          <Email />
          <ViberPhone />
          <FacebookLink />
          <LoanPurpose />
        </Box>
        <SecondaryButton text="Next" type="submit" />
      </Box>
    </StepBlock>
  )
}

export default GeneralInfo
