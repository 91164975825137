import { useState } from "react"
import {
  Box,
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Container,
  useMediaQuery,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import Image from "static/rule_with_coins_olp.png"
import { BaseAccordion } from "../../base/Accordion"

const faqList = [
  {
    title: "What is Auto Loan Sangla OR/CR?",
    description: [
      "An Auto Loan Sangla OR/CR is a type of secured loan where the borrower uses their vehicle's Official Receipt (OR) and Certificate of Registration (CR) as collateral. This type of loan is popular in the Philippines for those who need quick access to cash but want to retain the use of their vehicle.",
    ],
  },
  {
    title: "Will I still be able to use my car if I applied, and got approved, for an ORCR loan?",
    description: [
      "Yes. We will only safekeep the client’s ORCR and release it once the loan gets fully paid.",
    ],
  },
  {
    title: "How much is the loanable amount? How about the interest rate and payment term?",
    description: [
      "The loanable amount ranges from php 100K to php 2M. Interest rate depends on the profile of the client but does not exceed 5% per month. The payment term is from 12 months to 24 months.",
    ],
  },
  {
    title: "What cars are eligible?",
    description: [
      "Currently we accept only passenger or pick-up cars registered to the borrower, not older than 10 years. More details can be found here",
    ],
  },
  {
    title: "What are the documentary requirements?",
    description: [
      "Photocopy of one (1) valid government-issued photo-bearing ID",
      "Latest Electric or Water Bill. If not in the name of the borrower, you must submit additional proof of residence - any documents under your name and with your current address.",
      "Photocopy of OR/CR (must be under the borrower’s name, unencumbered and owned for at least 6 months)",
      "One of the following documents: Latest one month payslip, Latest ITR or BIR Form 2316",
    ],
  },
  {
    title: "How can the clients pay for their loan?",
    description: ["The clients can pay their loan via Post-dated Checks (PDCs) or bank transfer."],
  },
]

const FaqItem = (props) => {
  const { title, description, id, handleChange, expanded } = props

  const selected = expanded === id

  const icon = selected ? (
    <RemoveIcon sx={{ color: "secondary.main", fontWeight: 700 }} />
  ) : (
    <AddIcon sx={{ color: "secondary.main", fontWeight: 700 }} />
  )

  const ListDescription = (props) => (
    <Box component="ul" sx={{ ml: -2 }}>
      {props.description.map((item, key) => (
        <Typography
          component="li"
          variant="body1"
          key={key}
          sx={{ mb: 1, color: "#49546B", fontFamily: "inter" }}
        >
          {item}
        </Typography>
      ))}
    </Box>
  )

  const SingleDescription = () => (
    <Typography
      component="p"
      variant="body1"
      sx={{ mb: 1, color: "#49546B", lineHeight: "22px", fontFamily: "inter" }}
    >
      {props.description[0]}
    </Typography>
  )

  return (
    <BaseAccordion
      expanded={selected}
      onChange={handleChange(id)}
      sx={{ mb: 2, borderRadius: 1 }}
      variant="outlined"
    >
      <AccordionSummary
        expandIcon={icon}
        aria-controls={`content-${id}`}
        id={`header-${id}`}
        sx={{ py: { xs: 0.5, md: 1 }, px: { xs: 2, md: 3 } }}
      >
        <Typography
          variant="h5"
          sx={{
            width: "95%",
            flexShrink: 0,
            color: selected ? "secondary.main" : "text.primary",
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{ mt: -1, mb: 2.5 }} />
        {description.length > 1 ? (
          <ListDescription description={description} />
        ) : (
          <SingleDescription description={description} />
        )}
      </AccordionDetails>
    </BaseAccordion>
  )
}

const Faq = () => {
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <Box id="faq" sx={{ backgroundColor: "bg.main", display: "flex", justifyContent: "center" }}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xl={6}>
            <Box
              sx={{
                flexGrow: 1,
                mt: { xs: 5, lg: 12.5 },
                mr: { xs: 0, lg: 2 },
                mb: { xs: 5, lg: 0 },
              }}
            >
              <Typography variant="h3" sx={{ mb: 3 }}>
                FAQ
              </Typography>

              {faqList.map((item, id) => (
                <FaqItem
                  title={item.title}
                  description={item.description}
                  handleChange={handleChange}
                  id={id}
                  key={id}
                  expanded={expanded}
                />
              ))}
            </Box>
          </Grid>

          {matches && (
            <Grid item xl={6}>
              <Box
                component="img"
                sx={{
                  height: 530,
                  width: 561,
                  mt: { md: 10, xl: 15 },
                  ml: { xl: 25, lg: 15, md: 10 },
                  mb: 12,
                }}
                alt="Documents"
                src={Image}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default Faq
