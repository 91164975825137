import { Box, SvgIcon, Typography } from "@mui/material"

import { ReactComponent as Call } from "static/icons/call.svg"

const CallIcon = (props) => <SvgIcon component={Call} inheritViewBox {...props} />

const CallPhone = () => {
  const phone = "+63 919 170 0001"

  return (
    <Box sx={{ display: "flex", mx: 2, alignItems: "center" }}>
      <CallIcon sx={{ color: "#fff" }} />
      <Typography variant="h6" component="h6" sx={{ ml: 1.5, fontWeight: 600, width: 150 }}>
        {phone}
      </Typography>
    </Box>
  )
}

export default CallPhone
