import { useEffect } from "react"
import { Typography } from "@mui/material"

import ApplicationLayout from "../../Layout"
import StepsLayout from "../Layout"

import useAppStore from "store"
import AppFields from "./AppFields"

const Step1 = () => {
  const steps = useAppStore((state) => state.steps)

  useEffect(() => {
    window.scrollTo(0, 0)

    if (typeof window.dataLayer !== "undefined" && window.dataLayer !== null) {
      window.dataLayer.push({ event: "application_started" })
    }
  }, [])

  return (
    <ApplicationLayout>
      <StepsLayout>
        <Typography variant="h4" component="h4" sx={{ mb: { xs: 2, md: 2.5 } }}>
          Application
        </Typography>

        <Typography variant="h6" component="h6" sx={{ fontWeight: 400, mb: { xs: 2, md: 2.5 } }}>
          Complete a brief application form, and we`ll reach out to invite you to our office
        </Typography>

        <AppFields />
      </StepsLayout>
    </ApplicationLayout>
  )
}

export default Step1
