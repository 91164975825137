import { Typography, useMediaQuery } from "@mui/material"

const Header = (props) => {
  const { title } = props

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <Typography
      variant="h6"
      sx={{
        width: matches ? "80%" : "80%",
        flexShrink: 0,
        textTransform: "uppercase",
        mt: 0.5,
      }}
    >
      {title}
    </Typography>
  )
}

export default Header
