import { Box, Container, Typography, CircularProgress, useMediaQuery } from "@mui/material"

import ApplicationLayout from "../../Layout"
import { useEffect } from "react"

const Waiting = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <ApplicationLayout>
      <Container maxWidth="lg">
        <Box
          sx={{
            height: { xs: 600, xl: 800 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={matches ? 120 : 80} />
          <Typography
            variant="h3"
            sx={{ mt: { xs: 4, md: 6 }, mb: { xs: 1, md: 2 }, textAlign: "center" }}
          >
            Please wait
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "center", fontFamily: "inter" }}>
            It usually takes couple of minutes
          </Typography>
        </Box>
      </Container>
    </ApplicationLayout>
  )
}

export default Waiting
