import { Box, Typography } from "@mui/material"

import useAppRequest from "hooks/use_app_request"

import StepBlock from "components/shared/StepBlock"

import ReferenceStatus from "components/shared/fields/ReferenceStatus"
import ReferenceFullName from "components/shared/fields/ReferenceFullName"
import ReferencePhoneNumber from "components/shared/fields/ReferencePhoneNumber"
import SecondaryButton from "components/shared/buttons/Secondary"

const References = () => {
  const stepName = "references"

  const { handleSubmitReferences } = useAppRequest(stepName, {
    reference1_id: "reference1_id",
    reference1_full_name: "reference1_full_name",
    reference1_phone_number: "reference1_phone_number",
    reference2_id: "reference2_id",
    reference2_full_name: "reference2_full_name",
    reference2_phone_number: "reference2_phone_number",
  })

  return (
    <StepBlock stepName={stepName} stepTitle="References">
      <Box component="form" onSubmit={handleSubmitReferences}>
        <Box sx={{ maxWidth: "388px" }}>
          <Box>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 700, my: 2 }}>
              Reference 1
            </Typography>
            <ReferenceStatus number="1" />
            <ReferenceFullName number="1" />
            <ReferencePhoneNumber number="1" />
          </Box>

          <Box>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 700, my: 2 }}>
              Reference 2
            </Typography>
            <ReferenceStatus number="2" />
            <ReferenceFullName number="2" />
            <ReferencePhoneNumber number="2" />
          </Box>
        </Box>
        <SecondaryButton text="Next" type="submit" />
      </Box>
    </StepBlock>
  )
}

export default References
