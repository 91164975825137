import { useEffect } from "react"

import { Modal, Box, Typography, Link } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import useAppStore from "store"

import Otp from "components/shared/fields/Otp"
import SubmitButton from "components/shared/buttons/Submit"
import useNavigateSearch from "hooks/use_navigate_search"

const OtpModal = (props) => {
  const { open, handleClose } = props

  const seconds = useAppStore((state) => state.otp_waiting_seconds)
  const updateKey = useAppStore((state) => state.updateKey)
  const sendApplicationOtp = useAppStore((state) => state.sendOtp)
  const checkApplicationOtp = useAppStore((state) => state.checkOtp)
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        updateKey("otp_waiting_seconds", seconds - 1)
      } else {
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  const navigateSearch = useNavigateSearch()

  const checkOtp = () => {
    // updateKey("backdrop", true)

    checkApplicationOtp()
      .then((response) => response.data)
      .then((data) => {
        const { status } = data

        updateKey("status", status)

        if (status === "accepted") {
          navigateSearch("/autoloan/application/success")
        } else {
          navigateSearch("/autoloan/application/reject")
        }
      })
      .catch((error) => {
        const messages = error?.response?.data?.error_messages

        if (messages) {
          updateErrorMessage("otp_code", (messages.otp_code && messages.otp_code[0]) || "")
        }
      })
      .finally(() => updateKey("backdrop", false))
  }

  const resendOtp = () => {
    sendApplicationOtp()
      .then((response) => response.data)
      .then((data) => {
        const { waiting_seconds } = data
        updateKey("otp_waiting_seconds", waiting_seconds)
      })
  }

  const SendOtpBlock = () => (
    <Link sx={{ cursor: "pointer", textDecoration: "none" }} onClick={resendOtp}>
      <Typography variant="body2" sx={{ my: 2.5 }}>
        Send again
      </Typography>
    </Link>
  )

  const WaitingOtpBlock = () => (
    <Typography variant="body2" sx={{ my: 2.5 }}>
      Send again after {seconds} sec...
    </Typography>
  )

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 470,
          backgroundColor: "background.paper",
          outline: "none",
          boxShadow: 24,
          p: { xs: 2, md: 3 },
          borderRadius: "10px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h5"
            sx={{ color: "text.primary", fontWeight: 500 }}
          >
            Confirmation
          </Typography>
          <CloseIcon
            sx={{
              ml: 2,
              width: 24,
              height: 24,
              cursor: "pointer",
              color: "text.secondary",
            }}
            onClick={handleClose}
          />
        </Box>

        <Box id="modal-modal-description">
          <Typography variant="body2" sx={{ mt: 2.5, color: "#49546B" }}>
            A confirmation code have been sent to your phone number.
          </Typography>

          {seconds > 0 ? <WaitingOtpBlock /> : <SendOtpBlock />}

          <Otp />

          <SubmitButton text="Confirm" onClick={checkOtp} />
        </Box>
      </Box>
    </Modal>
  )
}

export default OtpModal
