import { Button, Typography } from "@mui/material"

const Submit = (props) => {
  const { text } = props

  return (
    <Button
      variant="contained"
      disableElevation
      color="secondary"
      {...props} // Dont move under sx props
      sx={{
        py: 2,
        px: 6,
        textTransform: "none",
        whiteSpace: "nowrap",
        "&:disabled": {
          backgroundColor: "#FF9900",
          opacity: 0.5,
          color: "#fff",
        },
      }}
    >
      <Typography variant="h6">{text}</Typography>
    </Button>
  )
}

export default Submit
