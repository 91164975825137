import { styled } from "@mui/material/styles"
import { Slider } from "@mui/material"

const BaseSlider = styled(Slider)(({ theme }) => ({
  height: 10,
  "& .MuiSlider-thumb": {
    width: 26,
    height: 26,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      border: "5px solid white",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
  "& .MuiSlider-markLabel": {
    fontSize: "10px",
    marginTop: "8px",
    '&[data-index="0"]': {
      left: "3% !important",
    },
    '&[data-index="2"]': {
      left: "98% !important",
    },
  },
  "& .MuiSlider-mark": {
    opacity: 0,
    fontSize: "10px",
    "&.MuiSlider-markLabel": {
      opacity: 0,
      fontWeight: 100,
    },
  },

  "& .MuiSlider-rail": {
    color: theme.palette.bg.main,
    opacity: 1,
  },
}))

export default BaseSlider
