import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"
import InputMask from "react-input-mask"

const CarYear = () => {
  const id = "car_year"

  const validate = (value) => {
    if (value === "") return "This field is required"

    const currentYear = new Date().getFullYear()

    if (currentYear - parseInt(value) > 10) return "Your car must be less than 10 years old"
    if (currentYear < parseInt(value)) return "Invalid year"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  const mask = [/[0-2]/, /[0-9]/, /[0-9]/, /[0-9]/]

  return (
    <InputMask
      mask={mask}
      maskPlaceholder=""
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    >
      <MyTextField
        id={id}
        _label="Model year"
        placeholder="Model year"
        inputProps={{ inputMode: "numeric" }}
      />
    </InputMask>
  )
}

export default CarYear
