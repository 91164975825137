import { Button, Typography } from "@mui/material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"

const BackButton = (props) => {
  const { text } = props

  return (
    <Button
      variant="contained"
      startIcon={<ArrowBackIosIcon />}
      disableElevation
      {...props}
      sx={{
        py: 2,
        px: 2.5,
        mr: 2.5,
        textTransform: "none",
        whiteSpace: "nowrap",
        backgroundColor: "#EFEFEF",
        color: "#8C9099",
        ":hover": {
          backgroundColor: "#EFEFEF",
          color: "#8C9099",
        },
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 700 }}>
        {text}
      </Typography>
    </Button>
  )
}

export default BackButton
