import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"

const CompanyName = () => {
  const id = "company_name"

  const validate = () => ""

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  return (
    <MyTextField
      id={id}
      _label="Company name"
      placeholder="Enter your company name"
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    />
  )
}

export default CompanyName
