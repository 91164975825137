import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"

const FacebookLink = () => {
  const id = "facebook_link"

  const validate = () => {}

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  const handleChangeMiddleware = (event) => {
    event.target.value = event.target.value.replace(/ /g, "")

    handleChange(event)
  }

  return (
    <MyTextField
      id={id}
      _label="Link on Facebook"
      placeholder="https://www.facebook.com/nickname"
      value={inputValue}
      onChange={handleChangeMiddleware}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    />
  )
}

export default FacebookLink
