import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"

const CarPlateNumber = () => {
  const id = "car_plate_number"

  const validate = (value) => {
    if (value === "") return "This field is required"

    if (value.length < 1) return "Is too short (minimum is 1 character)"
    if (value.length > 12) return "Is too long (maximum is 12 characters)"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  return (
    <MyTextField
      id={id}
      _label="Plate number"
      placeholder="Plate number"
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    />
  )
}

export default CarPlateNumber
