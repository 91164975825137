import { Box, SvgIcon, Typography } from "@mui/material"

import { ReactComponent as Hours } from "static/icons/hours_blue.svg"

const HoursIcon = (props) => <SvgIcon component={Hours} inheritViewBox {...props} />

const WorkingHours = () => {
  const times = "8:00-17:00"

  return (
    <Box sx={{ display: "flex", ml: 2, mr: 4, alignItems: "center", mt: { xs: 3, md: 0 } }}>
      <HoursIcon />
      <Box sx={{ ml: 2, width: 150 }}>
        <Typography variant="h6" component="h6" sx={{ fontWeight: 700 }}>
          {times}
        </Typography>
        <Typography variant="h6" component="h6" sx={{ fontWeight: 700, color: "#808794" }}>
          business hours
        </Typography>
      </Box>
    </Box>
  )
}

export default WorkingHours
