import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"
import InputMask from "react-input-mask"

const CarMileage = () => {
  const id = "car_mileage"

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  return (
    <InputMask
      mask="999999"
      maskPlaceholder=""
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    >
      <MyTextField
        id={id}
        _label="Mileage"
        placeholder="Mileage"
        inputProps={{ inputMode: "numeric" }}
      />
    </InputMask>
  )
}

export default CarMileage
