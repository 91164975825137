import InputMask from "react-input-mask"
import { useEffect, useState } from "react"

import BaseField from "components/base/TextField"
import useInput from "hooks/use_input"

const Tin = () => {
  const id = "tin_document_number"
  const [mask, setMask] = useState()

  useEffect(() => {
    const initialMask = inputValue?.match(/\d/g)?.length ? "999-999-999-999" : "999-999-999"

    setMask(initialMask)
  }, [])

  const validate = (value) => {
    if (value === "") return "This field is required"
    if (value.includes("_")) return "Please enter valid ID number"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  return (
    <InputMask
      required
      maskPlaceholder="_"
      mask={mask}
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      onKeyDown={(e) => {
        if (inputValue?.match(/\d/g)?.length >= 9 && e.key != "Backspace")
          setMask("999-999-999-999")
      }}
      onKeyUp={() => {
        if (inputValue?.match(/\d/g)?.length < 10) setMask("999-999-999")
      }}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    >
      <BaseField id={id} _label="TIN (Tax Identification Number)" />
    </InputMask>
  )
}

export default Tin
