import { Container, Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material"
import Logo from "../Navbar/Logo"
import Link from "@mui/material/Link"
import secImage from "static/sec.png"
import npcImage from "static/npc.png"

const DesktopFooter = () => {
  // test
  return (
    <Box sx={{ borderTop: "1px solid #F0F0F5" }}>
      <Container maxWidth="xl">
        <Grid container columnSpacing={4} sx={{ my: 5.5 }}>
          <Grid item md={3}>
            <Logo sx={{ width: 208, height: 86, mt: "-45px" }} />
            <Typography variant="body2" component="p" sx={{ mt: 4.5, fontFamily: "inter" }}>
              OLP © All rights reserved.
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: "645px",
              }}
            >
              <Typography variant="body2" component="p" sx={{ mb: 2, fontFamily: "inter" }}>
                OLP is a registered online lending platform of Online Loans Pilipinas Financing Inc.
                a duly registered Financing Company with SEC Certificate of Incorporation №
                CS201726430 and Certificate of Authority №1181
              </Typography>
              <Typography variant="body2" component="p" sx={{ mb: 2, fontFamily: "inter" }}>
                601 Summit One Office Tower, 530 Shaw Boulevard, Barangay Highway Hills, Mandaluyong
                City
              </Typography>
              <Typography variant="body2" component="p" sx={{ mb: 2, fontFamily: "inter" }}>
                Study the terms and conditions in the Disclosure Statement before proceeding with
                the loan transaction
              </Typography>

              <Stack spacing={4} direction="row" sx={{ cursor: "pointer" }}>
                <Link
                  component="a"
                  target="_blank"
                  color="secondary"
                  href="/autoloan/TERMS&CONDITIONS_OLP.pdf"
                >
                  <Typography variant="body2" component="p" sx={{ mb: 2 }}>
                    Terms and conditions
                  </Typography>
                </Link>

                <Link
                  component="a"
                  target="_blank"
                  color="secondary"
                  href="/autoloan/PRIVACY_POLICY_OLP.pdf"
                >
                  <Typography variant="body2" component="p" sx={{ mb: 2 }}>
                    Privacy policy
                  </Typography>
                </Link>
              </Stack>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { md: "flex-start", xl: "flex-end" },
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 54,
                  width: 86,
                }}
                alt="SEC"
                src={secImage}
              />

              <Box
                component="img"
                sx={{
                  height: 54,
                  width: 113,
                  ml: 5.5,
                }}
                alt="NPC"
                src={npcImage}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const MobileFooter = () => {
  return (
    <Box sx={{ borderTop: "1px solid #F0F0F5" }}>
      <Container maxWidth="xl" sx={{ my: 2.5 }}>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Logo
                mobile="true"
                sx={{
                  width: 208,
                  height: 86,
                  ml: { xs: -4, sm: -6 },
                  mt: { xs: "-22px" },
                }}
              />
              <Box sx={{ display: "flex" }}>
                <Box
                  component="img"
                  sx={{
                    height: 31,
                    width: 49,
                  }}
                  alt="SEC"
                  src={secImage}
                />

                <Box
                  component="img"
                  sx={{
                    height: 31,
                    width: 65,
                    ml: 2.5,
                  }}
                  alt="NPC"
                  src={npcImage}
                />
              </Box>
            </Box>

            <Typography variant="body2" component="p" sx={{ mt: 2, mb: 4.5, fontFamily: "inter" }}>
              OLP © All rights reserved.
            </Typography>

            <Typography variant="body2" component="p" sx={{ mb: 2, fontFamily: "inter" }}>
              OLP is a registered online lending platform of Online Loans Pilipinas Financing Inc. a
              duly registered Financing Company with SEC Certificate of Incorporation № CS201726430
              and Certificate of Authority №1181
            </Typography>
            <Typography variant="body2" component="p" sx={{ mb: 2, fontFamily: "inter" }}>
              601 Summit One Office Tower, 530 Shaw Boulevard, Barangay Highway Hills, Mandaluyong
              City
            </Typography>
            <Typography variant="body2" component="p" sx={{ mb: 2, fontFamily: "inter" }}>
              Study the terms and conditions in the Disclosure Statement before proceeding with the
              loan transaction
            </Typography>

            <Stack spacing={4} direction="row" sx={{ mt: 3, mb: 1 }}>
              <Link component="a" target="_blank" href="/autoloan/TERMS&CONDITIONS_OLP.pdf">
                <Typography variant="body2" component="p" color="secondary">
                  Terms and conditions
                </Typography>
              </Link>

              <Link component="a" target="_blank" href="/autoloan/PRIVACY_POLICY_OLP.pdf">
                <Typography variant="body2" component="p" color="secondary">
                  Privacy policy
                </Typography>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const Footer = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return matches ? <DesktopFooter /> : <MobileFooter />
}

export default Footer
