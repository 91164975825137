import { Container, Box, Grid, Typography, SvgIcon } from "@mui/material"

import { ReactComponent as Memo } from "static/icons/memo.svg"
import { ReactComponent as Earth } from "static/icons/earth.svg"
import { ReactComponent as Call } from "static/icons/call.svg"
import { ReactComponent as Persons } from "static/icons/persons.svg"
import { ReactComponent as Coins } from "static/icons/coins.svg"

const IconComponent = (props) => <SvgIcon inheritViewBox {...props} />

const WhyUseItem = (props) => {
  const { icon, title, description, position } = props

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 2.5 }}>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            backgroundColor: "primary.main",
            width: 70,
            height: 70,
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </Box>

        <Box
          sx={{
            backgroundColor: "secondary.main",
            width: 28,
            height: 28,
            borderRadius: 20,
            outline: "3px solid white",
            position: "absolute",
            zIndex: 999,
            top: -14,
            left: 55,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{ fontWeight: 700, color: "text.light", mt: 0.7 }}
          >
            {position}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 2,
          mb: 1,
          fontWeight: 700,
        }}
      >
        <Typography
          variant="h5"
          component="h6"
          sx={{
            mb: { xs: 1, md: 1.5 },
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
        <Typography variant="body1" component="p" sx={{ fontFamily: "inter" }}>
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

const AppProcess = () => {
  return (
    <Container maxWidth="xl">
      <Box
        id="application_process"
        sx={{
          my: { xs: 6, md: 13 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography variant="h3" component="h3" sx={{ mb: { xs: 2.5 }, mt: { xs: 0, md: 2 } }}>
          Application process
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <WhyUseItem
              icon={<IconComponent component={Memo} sx={{ width: 32, height: 32 }} />}
              title="Eligibility check"
              description="Check eligibility criteria and complete the requirements"
              position={1}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <WhyUseItem
              icon={<IconComponent component={Earth} sx={{ width: 24, height: 24 }} />}
              title="Online application"
              description="Apply online through completing short application form"
              position={2}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <WhyUseItem
              icon={<IconComponent component={Call} sx={{ width: 24, height: 24 }} />}
              title="Confirmation call"
              description="Wait for our call to confirm the application details"
              position={3}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <WhyUseItem
              icon={<IconComponent component={Persons} sx={{ width: 24, height: 24 }} />}
              title="Personal Meeting"
              description="Meet with our specialist at a place and time convenient for you"
              position={4}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <WhyUseItem
              icon={<IconComponent component={Coins} sx={{ width: 24, height: 24 }} />}
              title="Money transfer"
              description="Get the money in your bank account"
              position={5}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default AppProcess
