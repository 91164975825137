import { Backdrop, CircularProgress } from "@mui/material"
import useAppStore from "../../store"

const BaseBackdrop = () => {
  const showBackdrop = useAppStore((state) => state.backdrop)

  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }} open={showBackdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default BaseBackdrop
