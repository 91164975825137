import { Container, Box, Stack, Typography, SvgIcon, useMediaQuery } from "@mui/material"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import { ReactComponent as Memo } from "static/icons/memo.svg"
import { ReactComponent as Earth } from "static/icons/earth.svg"
import { ReactComponent as Call } from "static/icons/call.svg"
import { ReactComponent as Persons } from "static/icons/persons.svg"
import { ReactComponent as Coins } from "static/icons/coins.svg"

const IconComponent = (props) => <SvgIcon inheritViewBox {...props} />

const WhyUseItem = (props) => {
  const { icon, title, description, showArrow } = props

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "row", md: "column" } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            backgroundColor: "primary.main",
            width: 70,
            height: 70,
            borderRadius: 35,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </Box>

        {showArrow && matches && (
          <Box
            sx={{
              flexGrow: 1,
              ml: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ flexGrow: 1, borderBottom: "2px solid #D0D0D0" }} />
            <KeyboardArrowRightIcon
              sx={{ position: "relative", right: 0, color: "#D0D0D0", ml: -1.7 }}
            />
          </Box>
        )}

        {showArrow && !matches && (
          <Box
            sx={{
              flexGrow: 1,
              mt: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ height: 30, borderRight: "2px solid #D0D0D0" }} />
            <KeyboardArrowDownIcon
              sx={{ position: "relative", bottom: 0, color: "#D0D0D0", mt: -1.7 }}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          mt: { xs: 0, md: 3 },
          ml: { xs: 2, md: 0 },
          mb: { xs: 0.5, md: 1.5 },
          fontWeight: 700,
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            mb: { xs: 1, md: 1.5 },
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
        <Typography variant="h6" component="h6" sx={{ fontFamily: "inter" }}>
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

const AppProcess = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <Container maxWidth="xl">
      <Box id="application_process" sx={{ my: { xs: 5, md: 13 } }}>
        <Typography variant="h3" component="h3">
          Application process
        </Typography>
        <Stack
          direction={matches ? "row" : "column"}
          spacing={matches ? 5 : 0}
          sx={{ mt: { xs: 3, md: 5.5 } }}
        >
          <WhyUseItem
            icon={<IconComponent component={Memo} sx={{ width: 32, height: 32 }} />}
            title="Eligibility check"
            description="Check eligibility criteria and complete the requirements"
            showArrow
          />
          <WhyUseItem
            icon={<IconComponent component={Earth} sx={{ width: 24, height: 24 }} />}
            title="Online application"
            description="Apply online through completing short application form"
            showArrow
          />
          <WhyUseItem
            icon={<IconComponent component={Call} sx={{ width: 24, height: 24 }} />}
            title="Confirmation call"
            description="Wait for our call to confirm the application details"
            showArrow
          />
          <WhyUseItem
            icon={<IconComponent component={Persons} sx={{ width: 24, height: 24 }} />}
            title="Personal Meeting"
            description="Meet with our specialist at a place and time convenient for you"
            showArrow
          />
          <WhyUseItem
            icon={<IconComponent component={Coins} sx={{ width: 24, height: 24 }} />}
            title="Money transfer"
            description="Get the money in your bank account"
          />
        </Stack>
      </Box>
    </Container>
  )
}

export default AppProcess
