import { Stack } from "@mui/material"
import Link from "@mui/material/Link"

const pages = [
  // { name: "Benefits", link: "#benefits" },
  { name: "Eligibility", link: "#eligibility" },
  { name: "Application process", link: "#application_process" },
  { name: "Required Documents", link: "#required_documents" },
  { name: "FAQ", link: "#faq" },
]

const Links = () => (
  <Stack
    direction="row"
    spacing={8}
    sx={{
      display: { xs: "none", md: "flex" },
      justifyContent: { xs: "none", md: "right" },
      alignItems: { xs: "none", md: "center" },
      flexGrow: 1,
      mx: 4,
      mt: 1,
    }}
  >
    {pages.map((page) => (
      <Link key={page.name} href={page.link} variant="h6" color="text.primary" underline="none">
        {page.name}
      </Link>
    ))}
  </Stack>
)

export { pages }

export default Links
