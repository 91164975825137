import { Box, Stack, Typography } from "@mui/material"

const Instructions = () => {
  const tips = [
    "Ensure your car is well-lit, but avoid direct sunlight which can cause harsh shadows.",
    "Make sure your car is washed and the interiors are tidy before you start snapping photos.",
    "Capture your car from various angles - front, rear, both sides, engine, dashboard and interior (7-10 photos).",
    "When photographing the dashboard, ensure the mileage and RPM are visible with the engine running.",
  ]

  return (
    <Box>
      <Box sx={{ mb: 2.5 }}>
        <Typography variant="body1">Follow the given tips</Typography>
      </Box>
      <Stack spacing={2}>
        {tips.map((tip, i) => (
          <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: 32,
                minHeight: 32,
                borderRadius: 16,
                backgroundColor: "bg.lightGreen",
              }}
            >
              <Typography variant="body2" sx={{ color: "primary.main" }}>
                {i + 1}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ ml: 1.5 }}>
              {tip}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  )
}

export default Instructions
