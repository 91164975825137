import { useState } from "react"
import useAppStore from "store"
import ENABLE_VALIDATIONS from "config/validations"

const useInput = (fieldName, validate) => {
  const inputValue = useAppStore((state) => state.fields[fieldName])
  const updateField = useAppStore((state) => state.updateField)
  const errorMessage = useAppStore((state) => state.error_messages[fieldName])
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)
  const [touched, setTouched] = useState(false)

  const handleChange = (e) => {
    const { value } = e.target

    updateField(fieldName, value)

    if (!touched) return

    const errorMessage = ENABLE_VALIDATIONS ? validate(value) : ""

    updateErrorMessage(fieldName, errorMessage)
  }

  const handleBlur = (e) => {
    if (touched) return

    setTouched(true)

    const { value } = e.target

    const errorMessage = ENABLE_VALIDATIONS ? validate(value) : ""

    updateErrorMessage(fieldName, errorMessage)
  }

  return {
    handleChange,
    inputValue,
    errorMessage,
    handleBlur,
    touched,
    updateField,
  }
}

export default useInput
