import { useState } from "react"

import { Box, Stack } from "@mui/material"

import useAppStore from "store"
import useAppRequest from "hooks/use_app_request"
import useNavigateSearch from "hooks/use_navigate_search"

import FullName from "components/shared/fields/FullName"
import Email from "components/shared/fields/Email"
import SubmitButton from "components/shared/buttons/Submit"
import PrivacyPolicy from "components/shared/Calculator/PrivacyPolicy"
import MobilePhone from "components/shared/fields/MobilePhone"
import CarYear from "components/shared/fields/CarYear"
import CarModel from "components/shared/fields/CarModel"
import CarIsCuf from "components/shared/fields/CarIsCuf"
import TransportType from "components/shared/fields/TransportType"
import OtpModal from "./OtpModal"

const AppFields = () => {
  const stepName = "create_application_short"

  const [open, setOpen] = useState(false)
  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false)

  const sendOtp = useAppStore((state) => state.sendOtp)
  const updateKey = useAppStore((state) => state.updateKey)
  const transportType = useAppStore((state) => state.fields.car_transport_type_id)

  const { handleCreateShortApp } = useAppRequest(stepName, {
    full_name: "full_name",
    mobile_phone: "mobile_phone",
    email: "email",
    car_transport_type_id: "car_info.transport_type_id",
    car_model: "car_info.name",
    car_year: "car_info.year",
    car_is_cuf: "car_info.is_cuf",
  })

  const navigateSearch = useNavigateSearch()

  const handleSubmit = (event) => {
    const callback = (status = null) => {
      if (status === "rejected") {
        navigateSearch("/autoloan/application/reject")
      } else {
        sendOtp().then((response) => {
          const { waiting_seconds } = response.data
          updateKey("otp_waiting_seconds", waiting_seconds)
          handleOpenModal()
        })
      }
    }

    handleCreateShortApp(event, callback)
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <FullName />
      <MobilePhone />
      <Email />
      <TransportType />

      {transportType === 1 && (
        <>
          <CarModel />

          <Stack direction="row" spacing={3} sx={{ mb: { xs: 2, md: 0 } }}>
            <CarYear />
            <CarIsCuf />
          </Stack>
        </>
      )}

      <Stack spacing={3} direction="row" sx={{ maxHeight: "52px", alignContent: "center" }}>
        <SubmitButton text="Send" type="submit" />
        <PrivacyPolicy />
      </Stack>
      <OtpModal open={open} handleClose={handleCloseModal} />
    </Box>
  )
}

export default AppFields
