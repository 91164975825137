// https://github.com/onurzorluer/react-image-file-resizer

import Resizer from "react-image-file-resizer"

const resizeImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      900,
      900,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      "file",
      600,
      600,
    )
  })

export default resizeImage
