import useAppStore from "store"

import {
  BaseAccordion,
  BaseAccordionDetails,
  BaseAccordionSummary,
} from "components/base/Accordion"

import Header from "components/shared/StepBlock/Header"
import SuccessIcon from "components/shared/SuccessIcon"
import ErrorIcon from "components/shared/ErrorIcon"

const StepBlock = (props) => {
  const { stepName, stepTitle, children } = props

  const steps = useAppStore((state) => state.steps)
  const currentStep = useAppStore((state) => state.current_step)
  const updateCurrentStep = useAppStore((state) => state.updateCurrentStep)

  const handleChange = (event, newExpanded) => {
    if (steps[stepName] === null || !newExpanded || steps[currentStep]) return

    updateCurrentStep(newExpanded ? stepName : currentStep)
  }

  return (
    <BaseAccordion expanded={currentStep === stepName} onChange={handleChange}>
      <BaseAccordionSummary id={stepName} sx={{ pl: 1, pr: { xs: 2, md: 2.5 } }}>
        <Header title={stepTitle} />
        {steps[stepName] !== null &&
          currentStep !== stepName &&
          (steps[stepName] ? <SuccessIcon /> : <ErrorIcon />)}
      </BaseAccordionSummary>

      <BaseAccordionDetails sx={{ p: 2 }}>{children}</BaseAccordionDetails>
    </BaseAccordion>
  )
}

export default StepBlock
