import axios from "axios"

let API_URL = "http://localhost:3001/api/v1" // Initialize API_URL
let $api // Initialize Axios instance

async function fetchBaseUrl() {
  if (process.env.NODE_ENV === "development") return

  try {
    const response = await fetch(window.location.origin + "/api_conf/settings.json")
    const data = await response.json()
    API_URL = data.url
  } catch (error) {
    console.error("Error fetching base URL:", error)
    throw error // Re-throw to handle the error outside this function
  }
}

await fetchBaseUrl()
// console.log()
$api = axios.create({
  baseURL: API_URL,
})

// Add interceptors after Axios instance is created
$api.interceptors.request.use((config) => {
  config.headers["Secret-Token"] = "vkq8RuVnylICbulkZ6tJ97GzHn7x4qU1"

  // config.headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`;
  // console.log("AXIOS: ", config.data);
  return config
})

$api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.code === "ERR_NETWORK") {
      window.location.href = "/technical_error"
      console.log("ERROR_MESSAGE: ", error.message)
      console.log("ERROR_CODE: ", error.code)
      console.log("ERROR_PARAMS: ", error.config.data)
      console.log("ERROR_URL: ", error.config.url)
    }

    return Promise.reject(error)
  },
)

export default $api
