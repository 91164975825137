import {
  // InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  Typography,
  Box,
} from "@mui/material"

const BaseSelect = (props) => {
  const { id, label, items, helperText, value, onChange, error, open, onOpen, onClose } = props

  return (
    <Box id={id}>
      <Typography variant="body2" color="text.middle" sx={{ mb: 1 }}>
        {label}
      </Typography>

      <FormControl fullWidth sx={{ mb: 2.5 }} error={error}>
        {/*<InputLabel shrink={false} htmlFor={id}>*/}
        {/*  <Typography variant="body1" color="text.primary">*/}
        {/*    {placeholder}*/}
        {/*  </Typography>*/}
        {/*</InputLabel>*/}

        <Select
          value={value}
          onChange={onChange}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          sx={{
            backgroundColor: "#F8F8FA",
            borderRadius: "4px",
            height: "50px",
            fontWeight: 500,
          }}
        >
          {items.map((item) => (
            <MenuItem key={item.id} value={item.id} sx={{whiteSpace: 'normal', maxWidth: "388px"}}>
              {item.name}
            </MenuItem>
          ))}
          {/*<MenuItem value="">*/}
          {/*  <em>Other</em>*/}
          {/*</MenuItem>*/}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  )
}

export default BaseSelect
