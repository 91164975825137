import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"
import InputMask from "react-input-mask"

const Otp = () => {
  const id = "otp_code"

  const validate = (value) => {
    if (value === "") return "This field is required"
    if (value.length < 4) return "Is the wrong length (should be 4 characters)"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  const mask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]

  return (
    <InputMask
      mask={mask}
      maskPlaceholder=""
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    >
      <MyTextField id={id} _label="Code from message" placeholder="0000" />
    </InputMask>
  )
}

export default Otp
