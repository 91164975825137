import { useEffect, useState } from "react"
import BaseSelect from "components/base/Select"

import useSelect from "hooks/use_select"
import useAppStore from "store"

const ReferenceStatus = (props) => {
  const { number } = props
  const id = `reference${number}_id`

  const [references, setReferences] = useState([])

  const getReferences = useAppStore((state) => state.getReferences)

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleOpen, handleClose, open } = useSelect(
    id,
    validate,
  )

  useEffect(() => {
    fetchReferences()
  }, [])

  const fetchReferences = () => {
    getReferences()
      .then((response) => response.data)
      .then((data) => {
        setReferences(data)
      })
  }

  return (
    <BaseSelect
      id={id}
      label="Status"
      placeholder="Select reference"
      error={errorMessage?.length > 0}
      value={inputValue || ""}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
      items={references}
      helperText={errorMessage}
    />
  )
}

export default ReferenceStatus
